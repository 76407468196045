<template>
  <v-form
    :value="value"
    @input="(v) => $emit('input', value)"
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-container class="d-flex justify-center">
      <v-btn color="blue lighten-1" text @click="fillFormWithRandomData"
        >Use random data</v-btn
      >
    </v-container>

    <v-text-field
      v-model="form_name"
      :counter="30"
      :rules="nameRules"
      label="Name"
      required
    ></v-text-field>

    <v-text-field
      v-model="form_position"
      :counter="40"
      :rules="positionRules"
      label="Position"
      required
    ></v-text-field>

    <v-text-field
      v-model="form_email"
      :rules="emailRules"
      label="E-mail"
      required
    ></v-text-field>

    <v-text-field
      v-model="form_phoneNumber"
      :rules="phoneNumberRules"
      label="Phone"
      required
    ></v-text-field>

    <v-text-field
      v-model="form_websiteLink"
      :rules="websiteLinkRules"
      label="Website"
      class="mb-3"
      required
    ></v-text-field>
    <v-container class="d-flex flex-wrap">
      <v-btn
        :disabled="!valid"
        color="blue lighten-1"
        class="mr-4 mb-4"
        @click="validate"
      >
        Fill template
      </v-btn>

      <v-btn color="error" @click="reset">Reset</v-btn>
    </v-container>
  </v-form>
</template>

<script>
import { createRandomTemplateData } from '../../data/local_data.js'
import {
  saveContentToStorage,
  getContentFromStorage,
  USER_DATA_FOR_TEMPLATE_KEY,
} from '../../data/storage'

export default {
  name: 'TokensFillingForm',

  props: ['value'],

  data: () => ({
    savingInterval: 5000,
    userTokenData: {
      name: '',
      position: '',
      email: '',
      phoneNumber: '',
      websiteLink: '',
    },
    valid: true,
    form_name: '',
    nameRules: [
      (v) => !!v || 'Name is required',
      (v) => (v && v.length <= 30) || 'Name must be less than 30 characters',
    ],
    form_position: '',
    positionRules: [
      (v) => !!v || 'Position is required',
      (v) =>
        (v && v.length <= 40) || 'Position must be less than 40 characters',
    ],
    form_email: '',
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    form_phoneNumber: '',
    phoneNumberRules: [
      (v) => !!v || 'Phone number is required',
      (v) =>
        /^\+?([0-9]{1,3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(
          v
        ) || 'Phone number must be valid',
    ],
    form_websiteLink: '',
    websiteLinkRules: [
      (v) => !!v || 'Website URL is required',
      (v) => {
        const pattern =
          /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
        return pattern.test(v) || 'Website must be a valid URL'
      },
    ],
  }),

  created() {
    this.userTokenData = getContentFromStorage(USER_DATA_FOR_TEMPLATE_KEY)
    if (this.userTokenData !== null) {
      this.fillFormFieldsFrom(this.userTokenData)
    }
    this.saveUserTokenDataIntervalId = setInterval(
      this.saveToLocalStorage,
      this.savingInterval
    )
  },

  beforeDestroy() {
    clearInterval(this.saveUserTokenDataIntervalId)
  },

  methods: {
    saveToLocalStorage() {
      saveContentToStorage(USER_DATA_FOR_TEMPLATE_KEY, this.userTokenData)
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.userTokenData = {
          name: this.form_name,
          position: this.form_position,
          email: this.form_email,
          phoneNumber: this.form_phoneNumber,
          websiteLink: this.form_websiteLink,
        }

        this.$emit('input', this.userTokenData)
      }
    },
    reset() {
      this.$refs.form.reset()
      this.userTokenData = {}
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    fillFormWithRandomData() {
      this.userTokenData = createRandomTemplateData()
      this.fillFormFieldsFrom(this.userTokenData)
    },
    fillFormFieldsFrom(userData) {
      this.form_name = userData.name
      this.form_position = userData.position
      this.form_email = userData.email
      this.form_phoneNumber = userData.phoneNumber
      this.form_websiteLink = userData.websiteLink
    },
  },
}
</script>
