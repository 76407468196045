<template>
  <v-app-bar app elevation="2" color="blue-grey-lighten-5">
    <v-row align="center">
      <v-col cols="1" class="d-flex justify-center">
        <img
          src="/file-earmark-medical-fill.svg"
          width="35"
          height="35"
          alt="CV generator logo"
        />
      </v-col>
      <v-col cols="10" class="d-flex justify-left">
        <v-app-bar-title>Cover letter generator</v-app-bar-title>
      </v-col>
      <v-col cols="1" class="d-flex justify-center">
        <img src="/vuetify.svg" width="35" height="35" alt="Vuetify logo" />
      </v-col>
    </v-row>
    <v-toolbar-items>
      <span class="h-2"></span>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
export default {
  name: 'TopNavigation',
}
</script>
