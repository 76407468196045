<template>
  <v-app id="template-generator">
    <TopNavigation />
    <v-main class="grey lighten-3">
      <v-container>
        <v-row>
          <v-col cols="12" sm="3">
            <v-sheet rounded="lg" min-height="268">
              <v-container>
                <TokensFillingForm v-model="userFormTokensData" />
              </v-container>
            </v-sheet>
          </v-col>
          <v-col cols="12" sm="9">
            <v-sheet min-height="70vh" rounded="lg">
              <TemplateManagementArea :userFormData="userFormTokensData" />
            </v-sheet>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" sm="12">
            <div class="text-center">
              <v-btn
                fab
                dark
                color="blue lighten-1"
                @click="moveToDocumentsArea"
                ><v-icon>mdi-clipboard-text-clock</v-icon></v-btn
              >
            </div>
          </v-col>
        </v-row>
        <v-row v-if="showDocumentsManagementArea" ref="scrollToMe">
          <v-col cols="12" sm="12">
            <v-sheet rounded="lg">
              <DocumentsManagementArea />
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <BottomFooter />
  </v-app>
</template>

<script>
import TopNavigation from '../components/TopNavigation.vue'
import BottomFooter from '../components/BottomFooter.vue'
import TokensFillingForm from '../components/forms/TokensFillingForm.vue'
import TemplateManagementArea from '../components/areas/TemplateManagementArea.vue'
import DocumentsManagementArea from '../components/areas/DocumentsManagementArea.vue'

export default {
  name: 'TemplateGeneratorView',

  data() {
    return {
      showDocumentsManagementArea: false,
      page: 1,
      userFormTokensData: {
        name: '',
        position: '',
        email: '',
        phoneNumber: '',
        websiteLink: '',
      },
    }
  },

  methods: {
    async moveToDocumentsArea() {
      this.showDocumentsManagementArea = !this.showDocumentsManagementArea

      if (this.showDocumentsManagementArea) {
        await new Promise((resolve) => setTimeout(resolve, 700))

        const component = this.$refs.scrollToMe
        component.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    },
  },

  components: {
    TopNavigation,
    BottomFooter,
    TokensFillingForm,
    TemplateManagementArea,
    DocumentsManagementArea,
  },
}
</script>
