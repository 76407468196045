<template>
  <v-footer elevation="2" color="grey-lighten-3" app>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-spacer></v-spacer>
          <span class="blue--text"
            >&copy; {{ new Date().getFullYear() }} NetCoreParty</span
          >
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: 'BottomFooter',
}
</script>
