<template>
  <v-container>
    <FileExporter
      :documentsForExport="sentDocuments"
      @import-documents="uploadDocsModel"
    />
    <DocumentsTable
      class="mb-5"
      :documents="sentDocuments"
      @add-document="addDocument"
      @edit-document="editDocument"
      @delete-document="dropDocument"
    />
  </v-container>
</template>

<script>
import DocumentsTable from '../DocumentsTable.vue'
import FileExporter from '../FileExporter.vue'

export default {
  name: 'DocumentsManagementArea',

  components: {
    DocumentsTable,
    FileExporter,
  },
  data() {
    return {
      sentDocuments: [],
      docsFromDb: null,
      showNewDocumentModal: false,
      resume_sent_date: '',
      menu: false,
    }
  },

  created() {
    this.docsFromDb = this.$store.state.docs
    if (this.docsFromDb !== null) {
      if (Array.isArray(this.docsFromDb) && this.docsFromDb.length > 0) {
        if (
          this.docsFromDb[0] !== null &&
          typeof this.docsFromDb[0] === 'object' &&
          !Array.isArray(this.docsFromDb[0])
        ) {
          this.sentDocuments = this.getDocsArrayFromDbaseObjectById(
            this.docsFromDb,
            1
          )
        }
      }
    }
  },
  methods: {
    filteredDocument(document) {
      const fieldsToExclude = ['id']
      const filtered = Object.entries(document).reduce((acc, [key, value]) => {
        if (!fieldsToExclude.includes(key)) {
          acc[key] = value
        }
        return acc
      }, {})
      return filtered
    },
    addDocument(newDocument) {
      if (this.sentDocuments == null) this.sentDocuments = []
      newDocument.id = this.createUniqueDocumentId()
      this.sentDocuments.push(newDocument)

      this.$store.commit('setData', this.sentDocuments)
      this.$store.dispatch('saveData')
    },
    getDocsArrayFromDbaseObjectById(array, objectId) {
      const target = array.find((item) => item.id === objectId)
      if (target) {
        return target.docs
      } else {
        return []
      }
    },

    editDocument(editedDocumentId, editedItem) {
      Object.assign(this.sentDocuments[editedDocumentId], editedItem)
    },
    dropDocument(documentId) {
      this.sentDocuments = this.sentDocuments.filter(
        (doc) => doc.id !== documentId
      )
      this.$store.dispatch('deleteDocumentById', documentId)
    },
    uploadDocsModel(documentsList) {
      this.pushUnique(this.sentDocuments, documentsList, 'id')
    },
    createUniqueDocumentId() {
      return Date.now()
    },
    pushUnique(destinationArray, fromArray, uniqueKey) {
      fromArray.forEach(function (newObj) {
        var exists = destinationArray.some(function (item) {
          return item[uniqueKey] === newObj[uniqueKey]
        })

        if (!exists) {
          destinationArray.push(newObj)
        }
      })
    },
  },
}
</script>
