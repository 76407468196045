<template>
  <v-container>
    <v-card class="d-flex flex-wrap mb-2" flat tile>
      <v-btn
        v-for="token in insertTokens"
        :key="token"
        dark
        class="blue lighten-1 mb-2 mr-1"
        rounded
        @click="insertTokenToContent(token)"
      >
        {{ token }}
      </v-btn>
    </v-card>
    <v-card class="d-flex flex-wrap mb-2" flat tile>
      <v-chip-group active-class="blue lighten-1--text" column>
        <v-chip
          v-for="snippet in userSnippets"
          :key="snippet.id"
          @click="insertSnippetToContent(snippet.name)"
        >
          {{ snippet.name }}
        </v-chip>
        <v-btn icon @click="isAddSnippetModalOpen = !isAddSnippetModalOpen"
          ><img
            src="/note-plus-outline.svg"
            width="20"
            height="20"
            alt="Add snippet logo"
        /></v-btn>
      </v-chip-group>
    </v-card>
    <v-card class="mb-3 pa-2" outlined>
      <v-row>
        <v-col cols="11">
          <v-card flat>
            <v-card-text
              class="custom-line-spacing hyphenate"
              v-html="finalContent"
            ></v-card-text>
          </v-card>
        </v-col>
        <v-col cols="1" class="d-flex justify-end">
          <v-btn
            class="ma-2"
            text
            icon
            color="blue lighten-2"
            @click="copyToClipboard"
          >
            <v-icon>mdi-content-copy</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <v-textarea
      outlined
      name="input-7-4"
      label="Place your template here"
      v-model="inputTemplateContent"
      rows="10"
      ref="inputArea"
    ></v-textarea>
    <v-snackbar
      v-model="isSnackbarOpen"
      :timeout="5000"
      right
      bottom
      outlined
      color="success"
      rounded="pill"
    >
      <v-row align="center" justify="center">
        <v-col cols="2">
          <v-icon color="green darken-2">mdi-checkbox-marked-circle</v-icon>
        </v-col>
        <v-col cols="7">
          {{ snackbarMessage }}
        </v-col>
        <v-col cols="3">
          <v-btn color="red" text @click="isSnackbarOpen = false">
            <v-icon color="red darken-2">mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-snackbar>

    <!-- Add Snippet Dialog -->
    <v-dialog v-model="isAddSnippetModalOpen" persistent max-width="600px">
      <v-card>
        <v-form
          ref="addSnippetForm"
          v-model="isAddSnippetFormValid"
          lazy-validation
        >
          <v-card-title>
            <span class="text-h5">Add new snippet</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6" sm="6" md="6">
                  <v-text-field
                    v-model="newSnippet.snippetName"
                    label="Name"
                    hint="Must be unique"
                    :rules="[snippetNameRules]"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <v-text-field
                    v-model="newSnippet.snippetDescription"
                    label="Description"
                    hint="Description of your snippet"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-model="newSnippet.snippetContent"
                    outlined
                    label="Content"
                    rows="10"
                    hint="Content of your snippet"
                    persistent-hint
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              @click="isAddSnippetModalOpen = !isAddSnippetModalOpen"
            >
              Close
            </v-btn>
            <v-btn color="warning" @click="addSnippetFormReset">Reset</v-btn>
            <v-btn
              @click="addSnippet"
              :disabled="!isAddSnippetFormValid"
              color="blue lighten-1"
            >
              Create
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  saveContentToStorage,
  getContentFromStorage,
  TEMPLATE_CONTENT_KEY,
} from '../../data/storage.js'

export default {
  name: 'TemplateManagementArea',

  props: {
    userFormData: {
      type: Object,
    },
  },

  data() {
    return {
      defaultUserData: {
        name: '',
        position: '',
        email: '',
        phoneNumber: '',
        websiteLink: '',
      },
      userSnippets: [],
      savingInterval: 5000,
      inputTemplateContent: '',
      mutatedTemplateContent: '',
      finalContent: '',
      isSnackbarOpen: false,
      snackbarMessage: 'This is default message!',
      insertTokens: ['name', 'position', 'email', 'phoneNumber', 'websiteLink'],
      isAddSnippetModalOpen: false,
      newSnippet: {
        snippetName: '',
        snippetDescription: '',
        snippetContent: '',
      },
      isAddSnippetFormValid: true,
    }
  },
  created() {
    this.inputTemplateContent =
      getContentFromStorage(TEMPLATE_CONTENT_KEY) ?? ''
    this.saveTemplateContentIntervalId = setInterval(
      this.saveToLocalStorage,
      this.savingInterval
    )
    this.$store.dispatch('fetchData')
  },
  beforeDestroy() {
    clearInterval(this.saveTemplateContentIntervalId)
  },
  watch: {
    userFormData() {
      this.setFinalContent()
    },
    inputTemplateContent(oldValue, newValue) {
      if (newValue === null || newValue === '') {
        return
      }
      this.setFinalContent()
    },
  },
  methods: {
    snippetNameRules(value) {
      if (!value) {
        return 'Snippet name is required'
      }
      if (!this.isSnippetNameUnique(value)) {
        return 'Snippet name must be unique'
      }
      return true
    },
    setFinalContent() {
      if (this.userFormData === null || this.inputTemplateContent === null) {
        return
      }

      this.mutatedTemplateContent = this.replaceTokensToUserData(
        this.userFormData,
        this.inputTemplateContent
      )

      this.mutatedTemplateContent = this.replaceSnippetsToUserData(
        this.userSnippets,
        this.mutatedTemplateContent
      )

      this.finalContent = this.addFramesToTokensIn(this.mutatedTemplateContent)
      this.finalContent = this.addFramesToSnippetsIn(this.finalContent)
    },
    replaceTokensToUserData(userInput, inputTemplate) {
      let result = inputTemplate
      Object.keys(userInput).forEach((key) => {
        const regex = new RegExp(`${key}`, 'g')
        result = result?.replace(regex, userInput[key])
      })
      return result
    },
    replaceSnippetsToUserData(snippets, inputTemplate) {
      let result = inputTemplate

      snippets.forEach((snippet) => {
        if (snippet.name && snippet.content) {
          const regex = new RegExp(snippet.name, 'g')
          result = result.replace(regex, snippet.content)
        }
      })
      return result
    },
    addFramesToTokensIn(contentWithUserData) {
      const tokenRegex = /\[\[\[(.*?)\]\]\]/g

      return contentWithUserData.replace(tokenRegex, (match, index) => {
        return `<span class="bold-bordered-text token-border custom-line-spacing">${index}</span>`
      })
    },
    addFramesToSnippetsIn(contentWithUserData) {
      const snippetRegex = /\{\{\{(.*?)\}\}\}/g

      return contentWithUserData.replace(snippetRegex, (match, index) => {
        return `<span class="bold-bordered-text snippet-border custom-line-spacing">${index}</span>`
      })
    },
    replaceHtmlTagsWithContent(inputString) {
      const regex = /<span\b[^>]*>(.*?)<\/span>/g

      return inputString.replace(regex, (match, content) => {
        return content
      })
    },
    copyToClipboard() {
      const textToCopy = this.replaceHtmlTagsWithContent(this.finalContent)

      const textarea = document.createElement('textarea')
      textarea.value = textToCopy
      document.body.appendChild(textarea)

      textarea.select()
      textarea.setSelectionRange(0, 99999)

      document.execCommand('copy')

      document.body.removeChild(textarea)

      this.showSnackbar('Copied to clipboard')
    },
    saveToLocalStorage() {
      saveContentToStorage(TEMPLATE_CONTENT_KEY, this.inputTemplateContent)
      this.showSnackbar('Autosave template content done')
    },
    insertTokenToContent(token) {
      const insertedText = ' ' + '[[[' + token + ']]]' + ' '

      this.updateInputArea(insertedText)
      this.setFinalContent()
    },
    insertSnippetToContent(snippetName) {
      const insertedText = ' ' + '{{{' + snippetName + '}}}' + ' '

      this.updateInputArea(insertedText)
      this.setFinalContent()
    },
    updateInputArea(text) {
      const textarea = this.$refs.inputArea.$refs.input

      let start = textarea.selectionStart
      let end = textarea.selectionEnd

      this.inputTemplateContent =
        this.inputTemplateContent.substring(0, start) +
        text +
        this.inputTemplateContent.substring(end)

      this.$nextTick(() => {
        textarea.selectionStart = textarea.selectionEnd = start + text.length
      })
    },
    addSnippet() {
      if (this.$refs.addSnippetForm.validate()) {
        const createdSnippet = {
          id: this.createUniqueSnippetId(),
          name: this.newSnippet.snippetName,
          description: this.newSnippet.snippetDescription,
          content: this.newSnippet.snippetContent,
        }
        this.updateUserSnippets(createdSnippet)
        this.addSnippetFormReset()
      }
    },
    addSnippetFormReset() {
      this.$refs.addSnippetForm.reset()
      this.newSnippet = {
        id: -1,
        snippetName: '',
        snippetDescription: '',
        snippetContent: '',
      }
    },
    createUniqueSnippetId() {
      return Date.now()
    },
    isSnippetNameUnique(name) {
      return !this.userSnippets.some((obj) => obj.name === name)
    },
    updateUserSnippets(snippetData) {
      if (this.isSnippetNameUnique(snippetData.name)) {
        this.userSnippets.push(snippetData)
      } else {
        return
      }
    },
    showSnackbar(message) {
      if (message) {
        this.snackbarMessage = message
      }

      this.isSnackbarOpen = true
    },
    findSnippetBy(searchedId) {
      return this.userSnippets.filter((item) => item.id === searchedId)[0]
    },
  },
}
</script>

<style>
.snippet-border {
  border-color: #f3b817;
}

.token-border {
  border-color: #1a8a38;
}

.bold-bordered-text {
  border-style: dashed;
  border-width: 3px;
  padding: 2px;
  font-weight: bold;
}

.custom-line-spacing {
  line-height: 2;
  word-wrap: break-word;
}

.hyphenate {
  overflow-wrap: break-word; /* Fallback for wrapping long words */
  hyphens: auto;
  text-align: justify; /* Optional: for demonstrating hyphenation with justified text */
  -webkit-hyphens: auto; /* For Safari */
  -ms-hyphens: auto; /* For IE and Edge */
  word-wrap: break-word; /* Older browsers that don't support overflow-wrap */
}
</style>
