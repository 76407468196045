const pako = require('pako')

function compress(rawData) {
  try {
    const data = stringToUint8Array(rawData)

    const compressedData = pako.deflate(data)

    const base64EncodedData = btoa(
      String.fromCharCode.apply(null, compressedData)
    )

    return base64EncodedData
  } catch (error) {
    console.error('Compression failed:', error)
    return null
  }
}

function decompress(compressedData) {
  const dataAsArray = base64ToUintArray(compressedData)
  const decompressedString = decompressData(dataAsArray)

  return decompressedString
}

function stringToUint8Array(str) {
  const encoder = new TextEncoder()
  return encoder.encode(str)
}

function base64ToUintArray(base64) {
  const binaryString = atob(base64)
  const len = binaryString.length
  let bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes
}

function decompressData(compressedData) {
  try {
    const decompressedData = pako.inflate(compressedData)

    const decoder = new TextDecoder()
    return decoder.decode(decompressedData)
  } catch (error) {
    console.error('Decompression failed:', error)
    return null
  }
}

export { compress, decompress }
