export { getRandomArrayElement, getRandomPositiveInteger, objectsHaveSameProps }

function getRandomPositiveInteger(a, b) {
  const lower = Math.ceil(Math.min(Math.abs(a), Math.abs(b)))
  const upper = Math.floor(Math.max(Math.abs(a), Math.abs(b)))

  const result = Math.random() * (upper - lower + 1) + lower

  return Math.floor(result)
}

function getRandomArrayElement(elements) {
  return elements[getRandomPositiveInteger(0, elements.length - 1)]
}

function objectsHaveSameProps(obj1, obj2) {
  const keys1 = Object.keys(obj1).sort()
  const keys2 = Object.keys(obj2).sort()

  if (keys1.length !== keys2.length) {
    return false
  }

  for (let i = 0; i < keys1.length; i++) {
    if (keys1[i] !== keys2[i]) {
      return false
    }
  }

  return true
}
