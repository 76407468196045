import { getRandomArrayElement, getRandomPositiveInteger } from '../helpers.js'

const ALPHABET = 'abcdefghijklmnopqrstuvwxyz'
const NAMES = [
  'Matthew',
  'Peter',
  'Katy',
  'Charlotte',
  'Max',
  'Ivan',
  'Ian',
  'Leigh',
  'Maria',
  'Marianne',
]
const DOMAINS = ['.com', '.net', '.org', '.io']
const POSITIONS = [
  'Only man in startup',
  'Chief Promt Engineer',
  'Crypto-miner Introvert',
  'DevOps dancer',
  'Junior Weekend Deploy Engineer',
  'Boss`s cousin',
  'Mom`s friend`s millionaire son',
  'Weird admin without beard',
]
const SEPARATORS = ['-', ' ']

function getRandomPhoneNumber() {
  const countryCode = getRandomPositiveInteger(0, 998)

  const part1 = getRandomPositiveInteger(0, 999).toString().padStart(3, '0')
  const part2 = getRandomPositiveInteger(0, 999).toString().padStart(3, '0')
  const part3 = getRandomPositiveInteger(0, 999).toString().padStart(4, '0')

  const sep1 = getRandomArrayElement(SEPARATORS)
  const sep2 = getRandomArrayElement(SEPARATORS)
  const sep3 = getRandomArrayElement(SEPARATORS)

  return `+${countryCode}${sep1}${part1}${sep2}${part2}${sep3}${part3}`
}

function getRandomWebsiteLink() {
  let domainName = 'https://www.'
  for (let i = 0; i < 5; i++) {
    domainName += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length))
  }
  domainName += getRandomArrayElement(DOMAINS)
  return domainName
}

function getRandomEmail() {
  let email = ''
  const localPartLength = Math.floor(Math.random() * 10) + 1
  const domainLength = Math.floor(Math.random() * 5) + 1

  for (let i = 0; i < localPartLength; i++) {
    email += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length))
  }

  email += '@'

  for (let i = 0; i < domainLength; i++) {
    email += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length))
  }

  email += '.com'

  return email
}

function getRandomName() {
  return getRandomArrayElement(NAMES)
}

function getRandomPosition() {
  return getRandomArrayElement(POSITIONS)
}

function createRandomTemplateData() {
  return {
    name: getRandomName(),
    position: getRandomPosition(),
    email: getRandomEmail(),
    phoneNumber: getRandomPhoneNumber(),
    websiteLink: getRandomWebsiteLink(),
  }
}

function createRandomSnippets() {
  return [
    {
      id: 1,
      name: 'MySnippet_1',
      description: 'A snippet for description of my skills',
      content: 'Best of the worst! Blah blah blah boring scripted text...',
    },
    {
      id: 2,
      name: 'MySnippet_2',
      description: 'A snippet for description of my skills',
      content: 'Best of the worst! Blah blah blah boring scripted text...',
    },
    {
      id: 3,
      name: 'MySnippet_3',
      description: 'A snippet for description of my skills',
      content: 'Best of the worst! Blah blah blah boring scripted text...',
    },
    {
      id: 4,
      name: 'MySnippet_4',
      description: 'A snippet for description of my skills',
      content: 'Best of the worst! Blah blah blah boring scripted text...',
    },
    {
      id: 5,
      name: 'MySnippet_5',
      description: 'A snippet for description of my skills',
      content: 'Best of the worst! Blah blah blah boring scripted text...',
    },
    {
      id: 6,
      name: 'MySnippet_6',
      description: 'A snippet for description of my skills',
      content: 'Best of the worst! Blah blah blah boring scripted text...',
    },
  ]
}

function createRandomSavedDocuments() {
  return [
    {
      id: 1,
      company: 'Test_1',
      role: 'Cattle watcher',
      websiteLink: 'https://some-music.com',
      sendingDate: '2024-04-08',
      coverLetterContent:
        'Hello, my name is Giovanni Georgio and I would like to play some electronic music instead of an another boring interview!',
      response: 'bye bye!',
    },
    {
      id: 2,
      company: 'Test_2',
      role: 'Bird feeder',
      websiteLink: 'https://blunt.com',
      sendingDate: '2024-04-07',
      coverLetterContent:
        'Hi, people in suits, just hire me, what are you waiting for?',
      response: 'bye bye!',
    },
    {
      id: 3,
      company: 'Test_3',
      role: 'Poor guy',
      websiteLink: 'https://leftovers.com',
      sendingDate: '2024-04-06',
      coverLetterContent:
        'Hello, I have heard about an opened position in your company without pay but with free leftovers after working day. I could not miss the opportunity...',
      response: 'bye bye!',
    },
    {
      id: 4,
      company: 'Test_4',
      role: 'Creepy guy',
      websiteLink: 'https://confidence.com',
      sendingDate: '2024-04-05',
      coverLetterContent:
        'Hello, I would like to work here! I am going to start tomorrow, thank you for this opportunity!',
      response: 'bye bye!',
    },
  ]
}

export {
  createRandomTemplateData,
  createRandomSavedDocuments,
  createRandomSnippets,
}
