import Vue from 'vue'
import App from './App.vue'

import store from '@/plugins/vuex.js'
import vuetify from '@/plugins/vuetify.js'
import '@mdi/font/css/materialdesignicons.css'

Vue.config.productionTip = false

new Vue({
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app')
