<template>
  <div id="app">
    <TemplateGeneratorView />
  </div>
</template>

<script>
import TemplateGeneratorView from './views/TemplateGeneratorView.vue'

export default {
  name: 'App',
  components: {
    TemplateGeneratorView,
  },
}
</script>
