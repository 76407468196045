<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-data-table
    :headers="headers"
    :items="documents"
    sort-by="sendingDate"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Sent Documents</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-container>
          <span>Total documents size (Kb): </span>
          <v-chip :color="getColor(totalDocumentsSize)" dark>
            <v-icon small left dark>mdi-weight</v-icon>
            {{ totalDocumentsSize }}
          </v-chip>
        </v-container>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="blue lighten-1"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              New Document
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="6" sm="6">
                    <v-text-field
                      v-model="editedItem.company"
                      label="Company name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="6">
                    <v-text-field
                      v-model="editedItem.role"
                      label="Role name"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.websiteLink"
                      label="Website link"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-menu
                      v-model="isDatePickerOpen"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="pickerDate"
                          label="Sent date"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="pickerDate"
                        @input="isDatePickerOpen = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="editedItem.response"
                      label="Response"
                      outlined
                    ></v-textarea>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-textarea
                      v-model="editedItem.coverLetterContent"
                      label="Letter content"
                      outlined
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to delete this item?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.coverLetterContent="{ item }">
      <v-chip :color="getColor(item.coverLetterContent)" dark>
        <v-icon small left dark>mdi-weight</v-icon>
        {{ calculateSizeInKb(item.coverLetterContent) }}
      </v-chip>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
      <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
    </template>

    <template v-slot:no-data>
      <span>No documents yet</span>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: ['documents'],

  data: () => ({
    pickerDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    isDatePickerOpen: false,
    dialog: false,
    dialogDelete: false,
    totalDocumentsSize: 0,
    headers: [],
    editedIndex: -1,
    editedItem: {
      company: '',
      role: '',
      websiteLink: '',
      sendingDate: '',
      coverLetterContent: '',
      response: '',
    },
    defaultItem: {
      company: '',
      role: '',
      websiteLink: '',
      sendingDate: '',
      coverLetterContent: '',
      response: '',
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },

  watch: {
    documents() {
      this.updateTotalDocumentsSize()
    },
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.initializeTable()
    this.updateTotalDocumentsSize()
  },

  methods: {
    updateTotalDocumentsSize() {
      this.totalDocumentsSize = this.documents
        .reduce((prev, curr) => {
          return prev + this.calculateSizeInKb(curr.coverLetterContent)
        }, 0)
        .toFixed(3)
    },
    calculateSizeInKb(content) {
      return new Blob([content]).size / 1000
    },
    initializeTable() {
      this.headers = this.createDocumentTableHeaders()
    },

    getColor(context) {
      const size = this.calculateSizeInKb(context)
      if (size > 1024) return 'red'
      else if (size > 500) return 'orange'
      else return 'green'
    },

    editItem(item) {
      this.editedIndex = this.documents.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      // this.editedIndex = this.documents.indexOf(item)
      this.editedIndex = item.id
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.$emit('delete-document', this.editedIndex)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      this.editedItem.sendingDate = this.pickerDate
      if (this.editedIndex > -1) {
        this.$emit('edit-document', this.editedIndex, this.editedItem)
      } else {
        this.$emit('add-document', this.editedItem)
      }
      this.close()
    },
    createDocumentTableHeaders() {
      return [
        {
          text: 'To company',
          value: 'company',
          align: 'start',
          sortable: false,
        },
        {
          text: 'Role',
          value: 'role',
          sortable: false,
        },
        {
          text: 'Website',
          value: 'websiteLink',
          sortable: false,
        },
        {
          text: 'Sending date',
          value: 'sendingDate',
          sortable: true,
        },
        {
          text: 'Letter size (Kb)',
          value: 'coverLetterContent',
          sortable: false,
        },
        {
          text: 'Response',
          value: 'response',
          sortable: false,
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ]
    },
  },
}
</script>
