import Vue from 'vue'
import Vuex from 'vuex'
import { deleteDocumentByIdFromIndexedDB } from '@/utils/indexedDbProvider.js'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    docs: null,
  },
  mutations: {
    setData(state, newData) {
      state.docs = newData
    },
    DELETE_DOCUMENT_BY_ID(state, id) {
      state.docs = state.docs.filter((doc) => doc.id !== id)
    },
  },
  actions: {
    async deleteDocumentById({ commit }, id) {
      try {
        await deleteDocumentByIdFromIndexedDB(commit, id)
      } catch (error) {
        console.error('Error deleting document:', error)
        throw error
      }
    },
    async saveData({ state }) {
      try {
        const db = await new Promise((resolve, reject) => {
          const request = window.indexedDB.open('sent-cv-documents', 1)
          request.onerror = reject
          request.onsuccess = () => resolve(request.result)
          request.onupgradeneeded = (event) => {
            const db = event.target.result
            db.createObjectStore('docs', { keyPath: 'id' })
          }
        })

        const transaction = db.transaction(['docs'], 'readwrite')
        const objectStore = transaction.objectStore('docs')

        if (state.docs.length > 0) {
          objectStore.put({ id: 1, docs: state.docs })
          console.log('Data saved successfully')
        }
      } catch (error) {
        console.error('Error saving data:', error)
      }
    },
    async fetchData({ commit }) {
      try {
        const db = await new Promise((resolve, reject) => {
          const dbRequest = window.indexedDB.open('sent-cv-documents', 1)
          dbRequest.onerror = reject
          dbRequest.onsuccess = () => resolve(dbRequest.result)
          dbRequest.onupgradeneeded = (event) => {
            const dbProvider = event.target.result
            dbProvider.createObjectStore('docs', { keyPath: 'id' })
          }
        })

        const transaction = db.transaction(['docs'], 'readonly')
        const objectStore = transaction.objectStore('docs')
        const request = objectStore.getAll()

        request.onsuccess = () => {
          commit('setData', request.result)
        }
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    },
  },
})

export default store
