const DB_NAME = 'sent-cv-documents'
const DB_VERSION = 1
const STORED_ENTITY_NAME = 'docs'

function deleteDocumentByIdFromIndexedDB(commit, id) {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open(DB_NAME, DB_VERSION)

    request.onerror = function (event) {
      reject(event.target.errorCode)
    }

    request.onsuccess = function (event) {
      const db = event.target.result

      const getRequest = db
        .transaction([STORED_ENTITY_NAME], 'readwrite')
        .objectStore(STORED_ENTITY_NAME)
        .get(1)

      getRequest.onsuccess = () => {
        const alteredObject = getRequest.result
        alteredObject.docs = alteredObject.docs.filter((item) => item.id !== id)
        const updateRequest = db
          .transaction([STORED_ENTITY_NAME], 'readwrite')
          .objectStore(STORED_ENTITY_NAME)
          .put(alteredObject)

        updateRequest.onsuccess = () => {
          commit('DELETE_DOCUMENT_BY_ID', id)
        }

        updateRequest.onerror = (event) => {
          console.error('Error updating object:', event.target.error)
        }
      }

      getRequest.onerror = function (event) {
        console.error('Error retrieving object:', event.target.error)
        reject(event.target.error)
      }
    }
  })
}

export { deleteDocumentByIdFromIndexedDB }
