<template>
  <v-container class="d-flex justify-space-between align-center">
    <v-container>
      <v-btn
        color="blue lighten-1"
        dark
        class="mb-2 mr-2"
        @click="exportDocumentsToJson"
        >Export as JSON</v-btn
      >
    </v-container>
    <v-container
      ><v-file-input
        label="Documents import"
        chips
        show-size
        truncate-length="50"
        @change="convertUploadedFileToDocumentsArray"
        v-model="inputFile"
      ></v-file-input
    ></v-container>
  </v-container>
</template>

<script>
import { compress, decompress } from '../data/compressor.js'

export default {
  props: ['documentsForExport'],

  data: () => ({
    inputFile: [],
  }),

  methods: {
    exportDocumentsToJson() {
      const deepCopyDocsArray = JSON.parse(
        JSON.stringify(this.documentsForExport)
      )
      const compressedDocuments = deepCopyDocsArray.map((document) => {
        document.coverLetterContent = compress(document.coverLetterContent)
        document.response = compress(document.response)
        return document
      })
      const data = JSON.stringify(compressedDocuments)
      const blob = new Blob([data], { type: 'application/json' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'exported-documents-' + Date.now() + '.json'
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
    },

    convertUploadedFileToDocumentsArray() {
      if (!this.inputFile) {
        return
      }

      var reader = new FileReader()

      reader.onload = (event) => {
        const importedDocs = JSON.parse(event.target.result)
        importedDocs.forEach((document) => {
          document.coverLetterContent = decompress(document.coverLetterContent)
          document.response = decompress(document.response)
        })
        this.$emit('import-documents', importedDocs)
      }

      reader.readAsText(this.inputFile)
    },
  },
}
</script>
