const TEMPLATE_CONTENT_KEY = 'template-content'
const USER_DATA_FOR_TEMPLATE_KEY = 'userdata-for-template'

function saveContentToStorage(key, content) {
  localStorage.setItem(key, JSON.stringify(content))
}

function getContentFromStorage(key) {
  return JSON.parse(localStorage.getItem(key))
}

export {
  TEMPLATE_CONTENT_KEY,
  USER_DATA_FOR_TEMPLATE_KEY,
  saveContentToStorage,
  getContentFromStorage,
}
